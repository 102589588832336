import React, { useEffect, useRef } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import WordpressPortfolioList from "../elements/portfolio/WordpressPortfolioList";
import { Link } from "react-router-dom";



const PortfolioWordpress = () => {
    const wordpress = useRef(null);
    useEffect(() => {
        setTimeout(() => {
        window.scrollTo({
            top: wordpress.current.offsetTop - 100,
            behavior: "smooth",
          })
        }, "10");
      }, [])
    return (
        <>
            <PageHelmet pageTitle='Counters' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Portfolio'} bg_image={"bg_image--5"}    />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">




                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 ">
                <div id="portfolio"></div>
                    <div className="portfolio-sacousel-inner"  >
                        <div className="container">
                            <div className="row text-center">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30">
                                        <h2 className="title">PRODUCING EXTRAORDINARY PORTFOLIO</h2>
                                        <p className="sub-heading-text">Choose your preference to start a project with us.</p>
                                    </div>

                                    
                                </div>
                            </div>
                            
                                {/* <Tabs>
                                        <div className="row justify-content-center">
                                            <div className="row text-center">
                                                <div className="col-lg-12">
                                                    <div className="tablist-inner">
                                                        <TabList className="pv-tab-button text-center mt--0">
                                                            <Tab><span>All</span></Tab>
                                                            <Tab><span>Wordpress</span></Tab>
                                                            <Tab><span>E-Commerce</span></Tab>
                                                            <Tab><span>Web Design</span></Tab>
                                                            <Tab><span>Custom PHP</span></Tab>
                                                        </TabList>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <TabPanel className="row">
                                            <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                                        </TabPanel>
                                
                                        <TabPanel className="row row--35">
                                        Wordpress
                                        </TabPanel>

                                        <TabPanel className="row row--35">
                                        E-Commerce
                                        </TabPanel>

                                        <TabPanel className="row row--35">
                                        Web Design
                                        </TabPanel>

                                        <TabPanel className="row row--35">
                                        Custom PHP
                                        </TabPanel>
                                   
                                </Tabs>
                             */}

                             <div className="row text-center portfolio-row" ref={wordpress}>
                                <div className="col-lg-12">
                                    <div className="portfolio-custom-menu">
                                        <div className="tablist-inner">
                                            <ul className='pv-tab-button text-center mt--0'>
                                                <li><Link to="/portfolio"><span>All</span></Link></li>
                                                <li class="active"><Link to="/portfolio/wordpress"><span>Wordpress</span></Link></li>
                                                <li><Link to="/portfolio/ecommerce"><span>E-Commerce</span></Link></li>
                                                <li><Link to="/portfolio/webdesign"><span>Web Design</span></Link></li>
                                                <li><Link to="/portfolio/customphp"><span>Custom PHP</span></Link></li>
                                            </ul>
                                        </div>
                                     </div>
                                </div>

                                
                                    <WordpressPortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="100" />
                               
                             </div>



                         
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                

            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}

        </>
        
    )
}

export default PortfolioWordpress;