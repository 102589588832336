import React, { Component } from "react";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col last-col ${branstyle}`}>
                    <li>
                        <img src="/assets/images/technologies/cms/wordpress-icon.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/cms/contentful.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/cms/webflow-svgrepo-com.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/cms/wix-svgrepo-com.svg" alt="Logo Images"/>
                    </li>
                    <li>
                         <img src="/assets/images/technologies/cms/drupal-2.svg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/technologies/cms/squarespace-one.svg" alt="Logo Images"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;