import React, { Component } from "react";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col last-col ${branstyle}`}>
                    <li>
                    <img className="small-logo" src="/assets/images/technologies/project/monday-1.svg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img className="small-logo" src="/assets/images/technologies/project/slack-new-logo.svg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/technologies/project/trello-logo.svg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img className="small-logo" src="/assets/images/technologies/project/jira-1.svg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/technologies/project/basecamp-logo-2019.svg" alt="Logo Images"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;