import React , {useState,useEffect} from 'react';
const baseURL = process.env.REACT_APP_API_URL;
const SITE_KEY =  process.env.REACT_APP_TOKEN_URL;

const Result = () => {
    return (
        <p className="success-message alert alert-success">Your Message has been successfully sent.</p>
    )
}
function ContactForm({props}) {
    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
          const isScriptExist = document.getElementById(id);
    
          if (!isScriptExist) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = id;
            script.onload = function () {
              if (callback) callback();
            };
            document.body.appendChild(script);
          }
    
          if (isScriptExist && callback) callback();
        }
    
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
          console.log("Script loaded!");
        });
      }, []);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [budget, setBudget] = useState('');
    const [description, setDescription] = useState('');
    const [ result,showresult ] = useState(false);
    const [loading, setLoading] = useState(false);
    /*const sendEmail = (e) => {
        e.preventDefault();
        console.log(e.target.fullname.value)
        console.log(e.target.phone.value)
        axios
        .post(baseURL, {
            name:e.target.fullname.value,
            phone:e.target.phone.value,
            email:e.target.email.value,
            budget:e.target.budget.value,
            description:e.target.description.value
        }).then((response) => {
            showresult(true);
            e.target.reset();
        });
       
    };*/
    const handleOnClick = e => {
        e.preventDefault();
        setLoading(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
            submitData(token);
          });
        });
    }
    const submitData = token => {
        // call a backend API to verify reCAPTCHA response
        fetch(baseURL+"sendmail", {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            "name": name,
            "email": email,
            "phone":phone,
            "budget":budget,
            "description":description,
            "g-recaptcha-response": token
          })
        }).then(res => res.json()).then(res => {
          setLoading(false);
          showresult(true);
          setName('');
          setBudget('');
          setDescription('');
          setEmail('');
          setPhone('');
        });
      }
    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form action="" onSubmit={handleOnClick}>
            <div className="rn-form-group">
                <input 
                type="text"
                name="fullname"
                placeholder="Your Name*"
                required
                onChange={e => setName(e.target.value)} value={name}
                />
            </div>

            <div className="rn-form-group">
                <input 
                type="text"
                name="phone"
                placeholder="Phone Number*"
                required
                onChange={e => setPhone(e.target.value)} value={phone}
                />
            </div>

            <div className="rn-form-group">
                <input 
                type="email"
                name="email"
                placeholder="Your Email*"
                required
                onChange={e => setEmail(e.target.value)} value={email}
                />
            </div>

            <div className="rn-form-group">
                <input 
                type="text"
                name="budget"
                placeholder="Your Budget (in USD)*"
                required
                onChange={e => setBudget(e.target.value)} value={budget}
                />
            </div>
            
            <div className="rn-form-group">
                <textarea 
                name="description"
                placeholder="Describe your project*"
                required
                onChange={e => setDescription(e.target.value)} value={description}
                >
                </textarea>
            </div>

            <div className="rn-form-group">
                {/* <button onClick={handleOnClick} disabled={loading}>{loading ? 'Submitting...' : 'Submit'}</button> */}
                <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit Now</button>
            </div> 
            <div className="rn-form-group">
                {loading ? 
                <div>
                  <div>
                  <svg  width="50" height="50" version="1.1" id="L9"  x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 0 0">
                    <path fill="#ff6f3b" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                      <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform>
                  </path>
                </svg>
                  </div>
                    
                </div> : null}
            </div> 

            <div className="rn-form-group">
                {result ? <Result /> : null}
            </div> 

            {/* <GoogleReCaptchaProvider reCaptchaKey="[Your recaptcha key]">
                <GoogleReCaptcha  />
            </GoogleReCaptchaProvider> */}
        </form>
    )
}
export default ContactForm;
