import React, { Component } from "react";
import { brandSlick } from "../../src/page-demo/script";
import Slider from "react-slick";

class TechnologySlider extends Component{
    render(){
        return(
            <React.Fragment>
                <Slider className="brand-slider" {...brandSlick}>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/html.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/wordpress.png" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/php.png" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/javascript.png" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/jquery.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/mvc.png" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/mvc.png" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/css.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/cakephp.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/bootstrap.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/laravel.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/codeIgniter.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/yii.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/mysql.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/woocommerce.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/git.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/nodejs.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/angularjs.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/adobe.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/opencart.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/github.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/invision.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2 technologies-brand">
                    <li>
                        <img src="/assets/images/technologies/envato.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                </Slider>
            </React.Fragment>
        )
    }
}
export default TechnologySlider;