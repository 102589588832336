import React, { Component } from "react";
import { brandSlick } from "../../src/page-demo/script";
import Slider from "react-slick";
import { MdBusinessCenter } from "react-icons/md";
import { FaNotesMedical } from "react-icons/fa";
import { IoMdSchool } from "react-icons/io";
import { FaMobile } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import { FaPizzaSlice } from "react-icons/fa";
import { GoLaw } from "react-icons/go";
import { FaShoppingCart } from "react-icons/fa";
import { RiComputerFill } from "react-icons/ri";
import { IoMdCar } from "react-icons/io";
import { IoMdSettings } from "react-icons/io";
import { FaPlane } from "react-icons/fa";
import { MdHotel } from "react-icons/md";
import { GrGraphQl } from "react-icons/gr";
import { MdEventAvailable } from "react-icons/md";
import { GoGraph } from "react-icons/go";

class Industries extends Component{
    render(){
        return(
            <React.Fragment>

                <h2 className="title text-center mb-3">INDUSTRIES SERVED</h2>
                <Slider className="brand-slider" {...brandSlick}>
                <ul className="brand-style-2">
                    <li>
                        <MdBusinessCenter />
                        <h4 class="mt-2">Business</h4>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <FaNotesMedical />
                        <h4 class="mt-2">Hospital</h4>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <IoMdSchool  />
                        <h4 class="mt-2">Education</h4>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <FaMobile  />
                        <h4 class="mt-2">Technology</h4>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <FaBuilding   />
                        <h4 class="mt-2">Real Estate</h4>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <FaPizzaSlice />
                        <h4 class="mt-2">Food</h4>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <GoLaw  />
                        <h4 class="mt-2">Law</h4>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <FaShoppingCart />
                        <h4 class="mt-2">E-Commerce</h4>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <RiComputerFill  />
                        <h4 class="mt-2">Web Agency</h4>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <IoMdCar/>
                        <h4 class="mt-2">Auto Mobile</h4>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <IoMdSettings/>
                        <h4 class="mt-2">Software</h4>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <FaPlane/>
                        <h4 class="mt-2">Travel</h4>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <MdHotel/>
                        <h4 class="mt-2">Hotel</h4>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <GrGraphQl/>
                        <h4 class="mt-2">Manufacturing</h4>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <MdEventAvailable />
                        <h4 class="mt-2">Event</h4>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <GoGraph  />
                        <h4 class="mt-2">Finance</h4>
                    </li>
                </ul>
                </Slider>
            </React.Fragment>
        )
    }
}
export default Industries;