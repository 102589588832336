import React, { Component } from "react";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list ${branstyle}`}>
                    <li>
                    <img src="/assets/images/technologies/crm/salesforce-2.svg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/technologies/crm/zoho-1.svg" alt="Logo Images"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;