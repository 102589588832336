import React, { Component } from "react";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col last-col ${branstyle}`}>
                    <li>
                        <img className="" src="/assets/images/technologies/frontend/html5-2.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img className="" src="/assets/images/technologies/frontend/css-3-logo-svgrepo-com.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/frontend/bootstrap.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/frontend/vue-js-icon.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/frontend/jquery-1.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/frontend/React-icon.svg.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img className="" src="/assets/images/technologies/frontend/angular-icon.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img className="small-logo" src="/assets/images/technologies/frontend/tailwind-css-icon.svg" alt="Logo Images"/>
                    </li>

                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;