import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";
import {Link} from "react-router-dom";


const PortfolioList = [
    {
        image: 'image-1',
        bigImage: '/assets/images/portfolio/projects/soy.png',
        category: 'E-Commerce',
        title: 'SOYLENT',
        link:"https://soylent.com/"
    },
    {
        image: 'image-2',
        bigImage: '/assets/images/portfolio/projects/biocoiff.png',
        category: 'E-Commerce',
        title: 'Biocoiff',
        link:"http://biocoiff.com/"
    },
    {
        image: 'image-3',
        bigImage: '/assets/images/portfolio/projects/codeblue.png',
        category: 'Technology',
        title: 'Codeblue',
        link:"https://codeblue.ventures/"
    },
    {
        image: 'image-4',
        bigImage: '/assets/images/portfolio/projects/cctv.png',
        category: 'E-Commerce',
        title: 'CCTVENGROS',
        link:"https://cctvengros.dk/"
    },
    {
        image: 'image-5',
        bigImage: '/assets/images/portfolio/projects/vaniac.png',
        category: 'Law',
        title: ' Vancouver International Arbitration',
        link:"https://vaniac.org/"
    },
    {
        image: 'image-6',
        bigImage: '/assets/images/portfolio/projects/mbstryve.png',
        category: 'Web Agency',
        title: 'mbstryve',
        link:"https://rigicgspl.com/mbstryve/#"
    },
    // {
    //     image: 'image-7',
    //     bigImage: '/assets/images/portfolio/projects/biocoiff-pro.png',
    //     category: 'E-Commerce',
    //     title: 'Biocoiff Pro',
    //     link:"https://www.biocoiff-pro.com/"
    // },
    // {
    //     image: 'image-8',
    //     bigImage: '/assets/images/portfolio/projects/willowpayments.png',
    //     category: 'Technology',
    //     title: 'Willow',
    //     link:"https://willowpayments.com/"
    // },
    // {
    //     image: 'image-9',
    //     bigImage: '/assets/images/portfolio/projects/ielts.png',
    //     category: 'Education',
    //     title: 'IELTS Global Tutor',
    //     link:"https://www.ieltsglobaltutor.com/"
    // },
    // {
    //     image: 'image-10',
    //     bigImage: '/assets/images/portfolio/projects/popnyard.png',
    //     category: 'E-Commerce',
    //     title: "Pop'n Yardy Party",
    //     link:"https://popnyardyparty.com/"
    // },
    // {
    //     image: 'image-11',
    //     bigImage: '/assets/images/portfolio/projects/edminify.png',
    //     category: 'Software',
    //     title: 'Edminify',
    //     link:"https://edminify.com/"
    // },
    // {
    //     image: 'image-12',
    //     bigImage: '/assets/images/portfolio/projects/sfox.png',
    //     category: 'Business',
    //     title: 'Sfox',
    //     link:"https://www.sfox.com/"
    // },
    // {
    //     image: 'image-13',
    //     bigImage: '/assets/images/portfolio/projects/studinthome.png',
    //     category: 'Real Estate',
    //     title: 'Studint',
    //     link:"https://studinthome.com/"
    // },
    // {
    //     image: 'image-14',
    //     bigImage: '/assets/images/portfolio/projects/twd.png',
    //     category: 'Training',
    //     title: 'TWD',
    //     link:"https://www.trainwithdanny.com/"
    // },
    // {
    //     image: 'image-15',
    //     bigImage: '/assets/images/portfolio/projects/bigyenmusic.png',
    //     category: 'Entertainment',
    //     title: 'Bigyen',
    //     link:"https://www.bigyenmusic.com/"
    // },
    // {
    //     image: 'image-16',
    //     bigImage: '/assets/images/portfolio/projects/eastvalueresearch.png',
    //     category: 'Software',
    //     title: 'East Value Research',
    //     link:"https://eastvalueresearch.com/"
    // },
    // {
    //     image: 'image-17',
    //     bigImage: '/assets/images/portfolio/projects/createamotive.png',
    //     category: 'Technology',
    //     title: 'create.a.motive',
    //     link:"https://createamotive.com/"
    // },
    // {
    //     image: 'image-18',
    //     bigImage: '/assets/images/portfolio/projects/mycashreward.png',
    //     category: 'E-Commerce',
    //     title: 'MyCash Reward',
    //     link:"https://mycashreward.co.uk/"
    // },
    // {
    //     image: 'image-19',
    //     bigImage: '/assets/images/portfolio/projects/mantechenterprises.png',
    //     category: 'Technology',
    //     title: 'Manufacturing Technology',
    //     link:"https://mantechenterprises.com/"
    // },
    // {
    //     image: 'image-20',
    //     bigImage: '/assets/images/portfolio/projects/saintpius.png',
    //     category: 'Education',
    //     title: 'Saint Pius',
    //     link:"https://www.saintpius.ca/"
    // },
    // {
    //     image: 'image-21',
    //     bigImage: '/assets/images/portfolio/projects/lansdowne.png',
    //     category: 'Business',
    //     title: 'Lansdowne Centre',
    //     link:"https://lansdowne-centre.com/"
    // },
    // {
    //     image: 'image-22',
    //     bigImage: '/assets/images/portfolio/projects/travelindustrysolutions.png',
    //     category: 'Travel Industry Solutions',
    //     title: 'Travel',
    //     link:"https://travelindustrysolutions.com/"
    // },
    // {
    //     image: 'image-23',
    //     bigImage: '/assets/images/portfolio/projects/menonregen.png',
    //     category: 'Hospital',
    //     title: 'Menon Regenerative Institute',
    //     link:"https://www.menonregen.com/"
    // },
    // {
    //     image: 'image-24',
    //     bigImage: '/assets/images/portfolio/projects/pousadasofportugal.png',
    //     category: 'Hotel',
    //     title: 'PARA Promotions',
    //     link:"https://www.pousadasofportugal.com/"
    // },
    // {
    //     image: 'image-25',
    //     bigImage: '/assets/images/portfolio/projects/missourilandco.png',
    //     category: 'Real Estate',
    //     title: 'Missouri Land Company',
    //     link:"https://missourilandco.com/"
    // },
    // {
    //     image: 'image-26',
    //     bigImage: '/assets/images/portfolio/projects/kidswonder.png',
    //     category: 'Education',
    //     title: 'Kids Wonder',
    //     link:"https://www.kidswonder.net/"
    // },
    // {
    //     image: 'image-27',
    //     bigImage: '/assets/images/portfolio/projects/pixelcocreative.png',
    //     category: 'Web Agency',
    //     title: 'Pixelco',
    //     link:"https://pixelcocreative.com.au/"
    // },
    // {
    //     image: 'image-28',
    //     bigImage: '/assets/images/portfolio/projects/publicimagemgmt.png',
    //     category: 'Entertainment',
    //     title: 'Public Image Management',
    //     link:"http://publicimagemgmt.com/"
    // },
    // {
    //     image: 'image-29',
    //     bigImage: '/assets/images/portfolio/projects/sunshinesolves.png',
    //     category: 'Web Agency',
    //     title: 'Sunshine Solution',
    //     link:"https://sunshinesolves.com/"
    // },
    // {
    //     image: 'image-30',
    //     bigImage: '/assets/images/portfolio/projects/tractive.png',
    //     category: 'Business',
    //     title: 'GPS Tracker',
    //     link:"https://tractive.com/"
    // },
    // {
    //     image: 'image-31',
    //     bigImage: '/assets/images/portfolio/projects/alexmariejordan.png',
    //     category: 'Entertainment',
    //     title: 'Alex Marie Jordan',
    //     link:"https://www.alexmariejordan.com/"
    // },
    // {
    //     image: 'image-32',
    //     bigImage: '/assets/images/portfolio/projects/blackwomengunowners.png',
    //     category: 'Trainning',
    //     title: 'Black Women Gun Owners',
    //     link:""
    // },
    // {
    //     image: 'image-33',
    //     bigImage: '/assets/images/portfolio/projects/seymourhealth.png',
    //     category: 'Hospital',
    //     title: 'Seymour Health Center',
    //     link:"https://www.seymourhealth.ca/"
    // },
    // {
    //     image: 'image-34',
    //     bigImage: '/assets/images/portfolio/projects/simplihomes.png',
    //     category: 'Real Estate',
    //     title: 'SimpliHomes',
    //     link:"https://www.simplihomes.com/"
    // },
    // {
    //     image: 'image-35',
    //     bigImage: '/assets/images/portfolio/projects/freshnestla.png',
    //     category: 'Food',
    //     title: 'Fresh Nest',
    //     link:"https://www.freshnestla.com/"
    // },
    // {
    //     image: 'image-36',
    //     bigImage: '/assets/images/portfolio/projects/havenunderdeck.png',
    //     category: 'Business',
    //     title: 'Havenunderdeck',
    //     link:"https://havenunderdeck.com/"
    // },
    // {
    //     image: 'image-37',
    //     bigImage: '/assets/images/portfolio/projects/carecube.png',
    //     category: 'Hospital',
    //     title: 'Carecube',
    //     link:"https://carecube.clinic/"
    // },
    // {
    //     image: 'image+38',
    //     bigImage: '/assets/images/portfolio/projects/preppersociety.png',
    //     category: 'Business',
    //     title: 'Canadian Prepper Society',
    //     link:"https://preppersociety.ca/"
    // },
    // {
    //     image: 'image-39',
    //     bigImage: '/assets/images/portfolio/projects/royalwholesaleelectric.png',
    //     category: 'Business',
    //     title: 'Royal Wholesale Electric',
    //     link:"https://www.royalwholesaleelectric.com/"
    // },
    // {
    //     image: 'image-40',
    //     bigImage: '/assets/images/portfolio/projects/motionarray.png',
    //     category: 'E-Commerce',
    //     title: 'Motion Array',
    //     link:"https://motionarray.com/"
    // },
    // {
    //     image: 'image-41',
    //     bigImage: '/assets/images/portfolio/projects/hottubaddict.png',
    //     category: 'Manufacturer',
    //     title: 'Hot Tub Addict',
    //     link:"https://hottubaddict.com/"
    // },
    // {
    //     image: 'image-42',
    //     bigImage: '/assets/images/portfolio/projects/mallowayvillage.png',
    //     category: 'Real Estate',
    //     title: 'Malloway Village',
    //     link:"https://mallowayvillage.ca/"
    // },
    // {
    //     image: 'image-43',
    //     bigImage: '/assets/images/portfolio/projects/menchies.png',
    //     category: 'Food',
    //     title: 'Menchies Frozen Yogurt',
    //     link:"https://www.menchies.com/"
    // },
    // {
    //     image: 'image-44',
    //     bigImage: '/assets/images/portfolio/projects/vandusengarden.png',
    //     category: 'Education',
    //     title: 'VanDusen Bloedel',
    //     link:"https://vandusengarden.org/"
    // },
    // {
    //     image: 'image-45',
    //     bigImage: '/assets/images/portfolio/projects/225liveevents.png',
    //     category: 'Business',
    //     title: '225 Live Events',
    //     link:"https://www.225liveevents.com/"
    // },
    // {
    //     image: 'image-46',
    //     bigImage: '/assets/images/portfolio/projects/baselayer.png',
    //     category: 'Food',
    //     title: 'Nom Noms',
    //     link:"https://dev1.baselayer.ca/"
    // },
    // {
    //     image: 'image-47',
    //     bigImage: '/assets/images/portfolio/projects/prettygrafik.png',
    //     category: 'E-Commerce',
    //     title: 'Pretty Grafik',
    //     link:"https://prettygrafik.com/store/"
    // },
    // {
    //     image: 'image-48',
    //     bigImage: '/assets/images/portfolio/projects/mobilelace.png',
    //     category: 'Education',
    //     title: 'Mobilelace',
    //     link:"https://mobilelace.com/"
    // },
    // {
    //     image: 'image-49',
    //     bigImage: '/assets/images/portfolio/projects/ipwr.png',
    //     category: 'Business',
    //     title: 'Integrated Power System',
    //     link:"http://ipwr.net/"
    // },
    // {
    //     image: 'image-50',
    //     bigImage: '/assets/images/portfolio/projects/swarmdigital.png',
    //     category: 'Web Agency',
    //     title: 'Swarm Digital Marketing',
    //     link:"https://www.swarmdigital.io/"
    // },
    // {
    //     image: 'image-51',
    //     bigImage: '/assets/images/portfolio/projects/virtualmedicare.png',
    //     category: 'Hospital',
    //     title: 'Medicare App',
    //     link:"https://www.virtualmedicare.com/"
    // },
    // {
    //     image: 'image-52',
    //     bigImage: '/assets/images/portfolio/projects/cncmasters.png',
    //     category: 'Manufacturer',
    //     title: 'CNC Masters',
    //     link:"https://www.cncmasters.com/"
    // },
    // {
    //     image: 'image-53',
    //     bigImage: '/assets/images/portfolio/projects/vinnyrusso.png',
    //     category: 'Trainning',
    //     title: 'Vinnyrusso',
    //     link:"https://vinnyrusso.com/"
    // },
    // {
    //     image: 'image-54',
    //     bigImage: '/assets/images/portfolio/projects/thejeremybowers.png',
    //     category: 'Real Estate',
    //     title: 'Philadelphia Real Estate',
    //     link:"https://thejeremybowers.com/"
    // },
    // {
    //     image: 'image-55',
    //     bigImage: '/assets/images/portfolio/projects/solcreative.png',
    //     category: 'Web Agency',
    //     title: 'Sol Creative',
    //     link:"https://solcreative.ca/"
    // },
    // {
    //     image: 'image-56',
    //     bigImage: '/assets/images/portfolio/projects/housename.png',
    //     category: 'Real Estate',
    //     title: 'HouseName',
    //     link:"https://housename.ca/"
    // },

]

class Portfolio extends Component{
    render(){
        let title = 'NEW PROJECTS',
        description = "SELECTED LATEST WORKS";
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    
                                    <p>{description}</p>
                                    <h2 className="title">{title}</h2>                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--40 mt_md--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioList.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <div className="thumbnail-inner">
                                        <div className={`thumbnail ${value.image}`}></div>
                                        <div className={`bg-blr-image ${value.image}`}></div>
                                    </div>
                                    <div className="content">
                                        <div className="inner">
                                            <p>{value.category}</p>
                                            <h4 className="title"><a href="/portfolio-details">{value.title}</a></h4>
                                            <div className="portfolio-button">
                                                <a  className="rn-btn" target="_blank" href={value.link}>View Details</a>
                                            </div>
                                        </div>
                                    </div>
                                    <a target="_blank" href={value.link} className="link-overlay"></a>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Portfolio;