import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>We gave the team a serious challenge on the size of the project and how long they had to build it. They achieved all the goals we set out. Great job. Already worked on multiple projects at the same time since long and getting exceptional results always. Go for them without a doubt!</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>-George Affleck , </span>Curve Communication</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Best work I've found so far! I highly recommend Rigic Global Solutions and I will be using them for my online development in the future! My brand is 100x more visible and able to grow because of the well done and intuitive enhancements from RGS.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>-Big Yen Music </span></h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>I had a wonderful experience working with Rigic Global Solutions. This team was efficient, helpful, and quick to respond. They were able to help me with the exact problems I wanted to fix and projects I wanted accomplished. They knew I was working on a deadline and made sure to work alongside me to get things done and make sure I was happy. I can highly recommend this team and would work with them in the future without hesitation.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>-Alex Jordan </span></h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Rigic &amp; team were able to quickly deliver high-quality code under a tight deadline. Communication and availability were excellent so we were able to revise and implement any necessary edits/changes efficiently and effectively so as not to delay the project timeline. We enjoyed working with them and hope to work on another project soon.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>-Jack Finio </span></h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>This is the second website that they have done for my business. And, again we are delighted. We will definitely return for any Wordpress sites we need. They are prompt, efficient and very easy to work with.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>-Sharifa Salaam</span></h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Professional, Great english, Very responsive. They are great.Team was there to explain and make sure we understand it all trough the process. Came with great ideas and help to improve the product.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>-The Pick App Market Place</span></h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>This job was completed with ease and in a very professional manner. Rigic Global Solutions was very helpful, timely and professional. They worked around my issues with ease and even had feedback to improve upon my website and brand visibility.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>-James Frolio </span></h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>I strongly recommend to hire this company. They did very fabulous job for me. I am kindly a strong and hard person but so far I worked with this company, they did exactly what I required them to do.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>-John Brighton</span></h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>I can't tell you how organized and on time they were with our project. I will use Rigic over and over. You can't go wrong with someone who communicates clearly and gets the job done as stated and quoted.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>-Boris Gonzalez</span></h6>
                                    </div>
                                </div>
                            </TabPanel>
                            
                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-1.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-2.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-3.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-4.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-5.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-6.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-7.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-8.jpg" alt="Testimonial Images"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/testimonial-2.jpg" alt="Testimonial Images"/> 
                                        </div>
                                    </div>
                                </Tab>
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;