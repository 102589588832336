import React, { Component } from "react";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col ${branstyle}`}>
                    <li>
                        <img src="/assets/images/technologies/cloud/amazon-web-services.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/cloud/google-cloud-3.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/cloud/digitalocean.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/cloud/azure-icon-svgrepo-com.svg" alt="Logo Images"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;