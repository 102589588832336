import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Portfolio from "../../component/HomeLayout/homeOne/Portfolio";
import PortfolioList from "../portfolio/PortfolioList";
import BrandTwo from "../../elements/BrandTwo";
import TechnologySlider from "../../elements/TechnologySlider";

// const TabOne = [
//     {
//         image: '01',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-01.jpg',
//         category: 'Web Design',
//         title: 'Design is a creative part'
//     },
//     {
//         image: '02',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-02.jpg',
//         category: 'Mobile App',
//         title: 'The service provide for designer'
//     },
//     {
//         image: '03',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-03.jpg',
//         category: 'Web Design',
//         title: 'Mobile App landing Design'
//     },
//     {
//         image: '04',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-04.jpg',
//         category: 'Mobile App',
//         title: 'Logo Design creativity'
//     },
//     {
//         image: '05',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-05.jpg',
//         category: 'Web Design',
//         title: 'T-shirt design is the part of design'
//     },
//     {
//         image: '06',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-06.jpg',
//         category: 'Logo Design',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: '07',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-07.jpg',
//         category: 'Freelancer',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: '08',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-08.jpg',
//         category: 'Logo Designer',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: '09',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
//         category: 'Logo Designer',
//         title: 'Getting tickets to the big show'
//     },
   
// ]

// const TabTwo = [
//     {
//         image: '06',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-06.jpg',
//         category: 'Logo Design',
//         title: 'Logo design is the main part for a designer'
//     },
//     {
//         image: '07',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-07.jpg',
//         category: 'Freelancer',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: '08',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-08.jpg',
//         category: 'App Landing',
//         title: 'Mobile App landign is a landing page design'
//     },
//     {
//         image: '09',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
//         category: 'Dasboard',
//         title: 'Dasboard design is the main part for data management'
//     },
//     {
//         image: '10',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-10.jpg',
//         category: 'T-shirt Design',
//         title: 'T-shirt design is the popular design for digital market'
//     },
//     {
//         image: '11',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-11.jpg',
//         category: 'Logo Designer',
//         title: 'Getting tickets to the big show'
//     },
// ]

// const TabThree = [
//     {
//         image: '04',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-04.jpg',
//         category: 'Mobile App',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: '05',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-05.jpg',
//         category: 'Web Design',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: '06',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-06.jpg',
//         category: 'Logo Design',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: '07',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-07.jpg',
//         category: 'Freelancer',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: '08',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-08.jpg',
//         category: 'Freelancer',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: '09',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
//         category: 'Freelancer',
//         title: 'Getting tickets to the big show'
//     },
// ]

// const TabFour = [
//     {
//         image: '06',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-06.jpg',
//         category: 'Logo Design',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: '07',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-07.jpg',
//         category: 'Freelancer',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: '08',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-08.jpg',
//         category: 'Logo Designer',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: '09',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
//         category: 'Freelancer',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: '10',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-10.jpg',
//         category: 'Logo Designer',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: '11',
//         bigImage: '/assets/images/portfolio/big/dp-big--portfolio-11.jpg',
//         category: 'Logo Designer',
//         title: 'Getting tickets to the big show'
//     },
// ]

class TabStyleThree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: 0,
            tab2: 0,
            tab3: 0,
            tab4: 0,
            isOpen: false,
        };
    }
    render() {
        const {column } = this.props;
        const { tab1, tab2, tab3, tab4, isOpen } = this.state;
        return (
            <div>
                <Tabs>
                    <div className="row text-center">
                        <div className="col-lg-12">
                            <div className="tablist-inner">
                                <TabList className="pv-tab-button text-center mt--0">
                                    {/* <Tab><span>All Project</span></Tab> */}
                                    <Tab><span>Web Design Tools</span></Tab>
                                    <Tab><span>Front end Technologies</span></Tab>
                                    <Tab><span>E-Commerce Platforms</span></Tab>
                                    
                                </TabList>
                            </div>
                        </div>
                    </div>

                    <TabPanel className="row--35">
                       <div className="rn-brand-area brand-separation bg_color--5 ">
                         <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-title">
                                <TechnologySlider /> 
                                </div>
                            </div>
                         </div>
                        </div>
                    </TabPanel>
            
                    <TabPanel className="row--35">
                    <div className="rn-brand-area brand-separation bg_color--5 ">
                         <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-title">
                                <TechnologySlider /> 
                                </div>
                            </div>
                         </div>
                        </div>
                    </TabPanel>

                    <TabPanel className="row--35">
                    <div className="rn-brand-area brand-separation bg_color--5 ">
                         <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-title">
                                <TechnologySlider /> 
                                </div>
                            </div>
                         </div>
                        </div>
                        
                    </TabPanel>


                </Tabs>
            </div>
        )
    }
}


export default TabStyleThree
