import React, { Component } from "react";

class Webdesign extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list three-col last-col ${branstyle}`}>
                    <li>
                        <img src="/assets/images/technologies/opensource/php-svgrepo-com.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/opensource/laravel-2.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/opensource/nodejs-icon.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/opensource/codeigniter.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/opensource/cakephp.svg" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/technologies/opensource/yii.svg" alt="Logo Images"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Webdesign;