import React, { Component } from "react";
import { brandSlick } from "../../src/page-demo/script";
import Slider from "react-slick";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                {/* <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-01.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-02.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-03.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-04.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-05.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-06.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-02.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-03.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-04.png" alt="Logo Images"/>
                    </li>
                </ul> */}
                <h2 className="custom-heading title text-center">ECSTATIC CLIENTS</h2>
                <Slider className="brand-slider technologies-grid" {...brandSlick}>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-1.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-2.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-3.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-4.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-5.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-6.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-7.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-8.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-9.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-10.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-11.jpg" alt="Logo Images"/>
                    </li>
                </ul>
                </Slider>
            </React.Fragment>
        )
    }
}
export default BrandTwo;